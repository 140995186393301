import React, { Component } from 'react';

import _, { identity } from 'lodash';

import ProvidersTable from './ProvidersTable';
import PricingLoadingSkeleton from './Skeleton';

import constants from '../common/constants.js';
import PopupModal from '../common/PopupModal/PopupModal';
import Tooltip from '../common/Tooltip/Tooltip';
import MaterialCheckIcon from '../core/MaterialIcons/MaterialCheckIcon';
import MaterialClearIcon from '../core/MaterialIcons/MaterialClearIcon';
import RequestMarketDataModel from '../RequestMarketData/RequestMarketDataModal';

import './../RequestMarketData/RequestMarketDataModal.css';
import './MapLeftBar.css';
import 'react-toastify/dist/ReactToastify.css';

const PROVIDERS_ORDER = {
  WEWORK: 1,
  REGUS: 2,
  SPACES: 3,
};

class MapLeftBar extends Component {
  _displayCoworking = false;
  _displaySuite = false;
  _displayEnterprice = false;

  constructor(props) {
    super(props);
    this.state = {
      newproduct: null,
      products: [],
      isModelOpen: false,
      checked: false,
      isSurveyModalOpen: false,
      isSuite: false,
      isCoworking: false,
      isEnterprise: false,
      isMarketModalOpen: false,
      isLoading: true,
    };
  }
  closeMarketModal = () => {
    this.setState({ isMarketModalOpen: false });
  };
  uncheckProductType = () => {
    this._displayCoworking = false;
    this._displayEnterprice = false;
    this._displaySuite = false;
  };

  roundToNearNum = (num) => {
    const rem = num % 10;

    if (rem > 5 && rem <= 9) {
      return num - rem + 10;
    } else if (rem < 5 && rem >= 1) {
      return num - rem;
    }

    return num;
  };

  findPercentile = (arr, per) => {
    const len = arr.length;
    const res = (per / 100) * (len - 1);

    if (res % 1 === 0) {
      return arr[res];
    }
    const lower = Math.floor(res);
    const avg = (arr[lower] + arr[lower + 1]) / 2;

    return avg;
  };

  displayMarket = (pdate = []) => {
    let disableBtn = false;
    const zoomlevel = sessionStorage.getItem('ZoomLevel')
      ? sessionStorage.getItem('ZoomLevel')
      : '';

    if (zoomlevel < 10) {
      disableBtn = true;
    } else {
      disableBtn = false;
    }

    return (
      <div className="centerText">
        {pdate.length < 1 ? constants.notAvailableData : constants.notEnoughData}
        <br></br>
        <div className="tooltip1">
          <button
            className="button primary mt-20"
            disabled={disableBtn}
            onClick={() => {
              this.setState({ isMarketModalOpen: true });
            }}
          >
            Request market data
          </button>
          {disableBtn && (
            <span className="tooltiptext1 tooltip__position">Zoom in to request market Data</span>
          )}
        </div>
        {this.state.isMarketModalOpen && (
          <PopupModal open={this.state.isMarketModalOpen} onClose={this.closeMarketModal}>
            <RequestMarketDataModel
              close={this.closeMarketModal}
              sdata={this.props.searchdata}
              centerData={this.props.centerData}
            />
          </PopupModal>
        )}
      </div>
    );
  };

  parseData = () => {
    this.uncheckProductType();
    const pData = this.props.providerdata;
    const priceData = this.props.resppricedata;
    const region = this.props.region;

    const self = this;

    let p5;
    let p95;
    let median;
    let priceDisplay;
    let providerDisplay;
    let prices = [];

    const res = JSON.parse(sessionStorage.getItem('result'));
    const userData = res && res.data && res.data.records[0] && res.data.records[0].fields;

    if (priceData && priceData.length > 0) {
      const prdata = JSON.parse(priceData).data;

      this.state.isLoading && this.setState({ isLoading: false });
      if (region && region[0] && prdata['priceList'] !== undefined) {
        prices = prdata.priceList;

        p5 = Math.floor(prices[0]);
        p95 = Math.floor(prices[prices.length - 1]);
        const half = Math.floor(prices.length / 2);

        // If the length of the priceList array is zero, then set the median value same as low
        median = !half ? p5 : Math.round((prices[half - 1] + prices[half]) / 2.0);
      } else {
        for (let i = 0; i < prdata.length; i++) {
          if (prdata[i].Provider === 'Iwg') {
            prdata.splice(i, 1);
          }
        }

        for (let i = 0; i < prdata.length; i++) {
          const { price } = prdata[i];

          prices.push(price);
        }

        prices.sort(function (a, b) {
          return a - b;
        });
        const mid = Math.floor(prices.length / 2);

        p5 = Math.round(this.findPercentile(prices, 10));
        p95 = Math.round(this.findPercentile(prices, 90));

        median = Math.round(
          prices.length % 2 !== 0 ? prices[mid] : (prices[mid - 1] + prices[mid]) / 2,
        );

        if (p5 === p95 && p5 === median) {
          const { error } = prdata[mid];

          p5 = Math.round(median - error);
          p95 = Math.round(median + error);
        }

        p5 = this.roundToNearNum(p5);
        p95 = this.roundToNearNum(p95);
        median = this.roundToNearNum(median);
      }

      if (prices && prices.length > 0) {
        if (p5 < 0 || p95 < 0) {
          priceDisplay = <div>{this.state.isLoading && <PricingLoadingSkeleton />}</div>;
        } else if (region.length == 0 || prdata['priceList'] !== undefined) {
          priceDisplay = (
            <div className="pricing">
              <div className="row">
                <div className="column">
                  <p className="pricing__type">Low</p>
                  <span className="boldPrice">${p5}</span>
                  <p className="pricing__type">monthly</p>
                </div>
                <div className="column">
                  <p className="pricing__type">Mid</p>
                  <span className="boldPrice">${median}</span>
                  <p className="pricing__type">monthly</p>
                </div>
                <div className="column">
                  <p className="pricing__type">High</p>
                  <span className="boldPrice">${p95}</span>
                  <p className="pricing__type">monthly</p>
                </div>
              </div>
            </div>
          );
        } else {
          priceDisplay = (
            <div className="pricing">
              <div className="row">
                <div className="column">
                  <p className="pricing__type">Low</p>
                  <span className="boldPrice">${p5}</span>
                  <p className="pricing__type">monthly</p>
                </div>
                <div className="column">
                  <p className="pricing__type">Mid</p>
                  <span className="boldPrice">${median}</span>
                  <p className="pricing__type">monthly</p>
                </div>
                <div className="column">
                  <p className="pricing__type">High</p>
                  <span className="boldPrice">${p95}</span>
                  <p className="pricing__type">monthly</p>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    if (pData && pData.length > 0) {
      let isSuite = false;
      let isEnterprise = false;
      let isCoWorking = false;

      const rawData = _.map(pData, (o) => {
        const data = o;

        if (data.provider_name.toUpperCase().trim() === 'WEWORK') {
          data.provider_name = 'WeWork';
        }
        if (data.provider_name.toUpperCase().trim() === 'REGUS') {
          data.provider_name = 'Regus';
        }
        if (data.provider_name.toUpperCase().trim() === 'SPACES') {
          data.provider_name = 'Spaces';
        }
        if (data.provider_name.toUpperCase().trim() === 'INDUSTRIOUS') {
          data.provider_name = 'Industrious';
        }
        if (
          data.provider_name.toUpperCase().trim() === 'NOVEL' ||
          data.provider_name.toUpperCase().trim() === 'NOVEL COWORKING'
        ) {
          data.provider_name = 'Expansive';
        }
        if (!isSuite && data.ProductType === 'Suite') {
          isSuite = true;
          self._displaySuite = true;
        }
        if ((!isCoWorking && data.ProductType === '') || data.ProductType === 'Unknown') {
          isCoWorking = true;
          self._displayCoworking = true;
        }

        if (!isEnterprise && data.ProductType === 'Enterprise') {
          isEnterprise = true;
          self._displayEnterprice = true;
        }

        return data.provider_name;
      });

      //Disable all checkbox
      const count = [];

      for (let i = 0; i < rawData.length; i++) {
        const val = rawData[i];

        count[val] = count[val] ? count[val] + 1 : 1;
      }

      const sortable = [];

      for (const v in count) {
        sortable.push([v, count[v]]);
      }
      sortable
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .reverse();

      let totalSum = 0;

      sortable.map((v) => {
        totalSum += v[1];
      });

      let otherSum = 0;

      const initialArr = sortable
        .filter(
          (val) =>
            val[0].toUpperCase().trim() === 'WEWORK' ||
            val[0].toUpperCase().trim() === 'REGUS' ||
            val[0].toUpperCase().trim() === 'SPACES',
        )
        .sort((a, b) => {
          const [nameOfFirstProvider] = a;
          const [nameOfSecondProvider] = b;

          return (
            PROVIDERS_ORDER[nameOfFirstProvider.toUpperCase()] -
            PROVIDERS_ORDER[nameOfSecondProvider.toUpperCase()]
          );
        });
      const secondArr = sortable.filter(
        (val) =>
          val[0].toUpperCase().trim() !== 'WEWORK' &&
          val[0].toUpperCase().trim() !== 'REGUS' &&
          val[0].toUpperCase().trim() !== 'SPACES',
      );

      const res1 = JSON.parse(sessionStorage.getItem('result'));
      const currentUser = res1.data.records[0].fields;
      let isEdgeUser1 = false;

      if (currentUser['MKII Version'] === 'Edge') {
        isEdgeUser1 = true;
      }
      let finalRes;

      if (isEdgeUser1) {
        finalRes = [...initialArr, ...secondArr];
      } else {
        finalRes = [...initialArr, secondArr[0], secondArr[1]];
      }

      finalRes.map((v) => {
        if (v) {
          otherSum += v[1];
        }
      });

      otherSum = totalSum - otherSum;

      if (pData.length < 2 || p5 < 0 || p95 < 0) {
        this.uncheckProductType();
        priceDisplay = (
          <div>
            <br />
            <br />
          </div>
        );
        providerDisplay = this.displayMarket(pData);
      } else {
        const sum = 0;

        if (sortable.length > 10) {
          this._displaySuite = true;
          this._displayCoworking = true;
        }
        if (sortable.length > 30) {
          this._displayEnterprice = true;
        }

        providerDisplay = (
          <ProvidersTable
            data={finalRes}
            otherData={secondArr}
            Other={otherSum}
            total={totalSum}
            setHoveredProvider={self.props.setHoveredProvider}
          />
        );
      }
    } else {
      this.uncheckProductType();
      if (this.props.loaded) {
        this.uncheckProductType();
        priceDisplay = (
          <div>
            <br />
            <br />
          </div>
        );
        providerDisplay = this.displayMarket();
      } else {
        priceDisplay = (
          <div className="pricing">
            <br />
            <br />
          </div>
        );
        providerDisplay = <div className="providerDisplay">Zoom in to view market data</div>;
      }
    }

    return (
      <div>
        <div className="card card__except">
          <h6 className="font-400">
            {' '}
            Price per seat (from comps)
            <Tooltip text={constants.pricePerSeatTooltip} val="price" />
          </h6>
          {(priceData.length == 0 || this.state.isLoading) && <PricingLoadingSkeleton />}
          {priceDisplay}
        </div>

        <div className="card">
          <h6 className="font-400">
            Providers
            <Tooltip text={constants.providersTooltip} />{' '}
          </h6>
          {providerDisplay}
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="leftSideBar">
        <div className="searchBar">
          <div id="geocoder" className="geocoder"></div>
        </div>
        <div className="leftcolumn">
          <div>{this.parseData()}</div>

          <div className="card">
            <h6 className="font-400">Products</h6>
            <form>
              <div className="box">
                <div>
                  <div className="boldProduct">
                    Coworking
                    <Tooltip text={constants.coworkingTooltip} />
                  </div>
                  {this._displayCoworking ? (
                    <div className="avail">
                      {' '}
                      <MaterialCheckIcon></MaterialCheckIcon> {constants.avail}
                    </div>
                  ) : (
                    <div className="notAvail">
                      {' '}
                      <MaterialClearIcon></MaterialClearIcon>
                      {constants.notAvail}
                    </div>
                  )}
                </div>
                <div>
                  <div className="boldProduct">
                    Private Suites
                    <Tooltip text={constants.suitesTooltip} />
                  </div>
                  {this._displaySuite ? (
                    <div className="avail">
                      {' '}
                      <MaterialCheckIcon></MaterialCheckIcon>
                      {constants.avail}
                    </div>
                  ) : (
                    <div className="notAvail">
                      <MaterialClearIcon></MaterialClearIcon>
                      {constants.notAvail}
                    </div>
                  )}
                </div>
                <div>
                  <div className="boldProduct">
                    Enterprise <Tooltip className="tool" text={constants.enterpriseTooltip} />
                  </div>
                  {this._displayEnterprice ? (
                    <div className="avail">
                      {' '}
                      <MaterialCheckIcon></MaterialCheckIcon>
                      {constants.avail}
                    </div>
                  ) : (
                    <div className="notAvail">
                      <MaterialClearIcon></MaterialClearIcon>
                      {constants.notAvail}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default MapLeftBar;
