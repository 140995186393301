import React, { Component } from 'react';

import axios from 'axios';
import _ from 'lodash';
import MapboxGl from 'mapbox-gl';
import { toast, ToastContainer } from 'react-toastify';

import './../Locations/MapLocations.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import 'react-toastify/dist/ReactToastify.css';
import add_client from './../../assets/images/add_location.svg';

import constants from '../common/constants.js';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import { providericons } from '../common/providerIconList';
import { toastify } from '../common/Toastify';
import CurrentLocation from '../currentLocation/currentLocation';
import RequestSurveyBtn from '../RequestSurvey/RequestSurveyBtn';
import { getKeysByValue } from '../Utils/Utils';

toast.configure();

class MapLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      showSearch: false,
      addClientBtn: true,
      place_name: null,
      showing: true,
      showingBtn: false,
      isModelOpen: false,
      isSurveyModalOpen: false,
      centerData: [],
      providerData: [],
      isRequestButtonEnabled: false,
      isMapPinsError: false,
    };
    this.mapContainer = React.createRef();
    this.currentLocationInput = React.createRef(null);
    this.currentAllMarkers;
  }

  getFilteredData = (markerPoints) => {
    const rawData = _.map(markerPoints.data, function (o) {
      const data = o;

      if (data.provider_name.toUpperCase().trim() === 'WEWORK') {
        data.provider_name = 'WeWork';
      }
      if (
        data.provider_name.toUpperCase().trim() === 'NOVEL' ||
        data.provider_name.toUpperCase().trim() === 'NOVEL COWORKING'
      ) {
        data.provider_name = 'Expansive';
      }

      return data.provider_name;
    });

    const count = [];

    for (let i = 0; i < rawData.length; i++) {
      const val = rawData[i];

      count[val] = count[val] ? count[val] + 1 : 1;
    }
    const sortable = [];

    for (const v in count) {
      sortable.push([v, count[v]]);
    }
    sortable
      .sort(function (a, b) {
        return a[1] - b[1];
      })
      .reverse();
    let fData;

    if (sortable.length > 5) {
      fData = sortable.splice(0, 5);
    } else {
      fData = sortable;
    }
    //top 5 providers logic

    const providers = fData.map((o) => {
      return o[0];
    });

    return providers;
  };

  componentDidUpdate(prevProps) {
    if (this.state.map && prevProps.hoveredProvider !== this.props.hoveredProvider) {
      let locationObj;

      if (this.props.hoveredProvider === null) {
        locationObj = this.currentAllMarkers;
      } else {
        locationObj = this.currentAllMarkers.filter(
          (location) =>
            location.providerName?.toLowerCase() === this.props.hoveredProvider?.toLowerCase(),
        );
      }

      const finalObject = {
        type: 'FeatureCollection',
        features: locationObj,
      };

      try {
        if (this.state.map.getLayer('point')) {
          this.state.map.removeLayer('point');
        }
        if (this.state.map.getSource('single-point')) {
          this.state.map.removeSource('single-point');
        }

        this.state.map.addSource('single-point', {
          type: 'geojson',
          data: finalObject,
        });
        this.state.map.addLayer({
          id: 'point',
          source: 'single-point',

          type: 'symbol',
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': 1,
          },
        });
      } catch (error) {
        console.error('Occured error while updating providers on the map');
      }
    }
  }

  componentDidMount() {
    const self = this;
    let zoomValue = 0;

    MapboxGl.accessToken = APPLICATION_HEADERS.mapBoxAccessToken;

    const showError = () => {
      return toastify({
        title: 'Invalid coordinates',
        type: 'error',
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
      });
    };

    if (
      self.props.URLData.lng < -180 ||
      self.props.URLData.lng > 180 ||
      isNaN(self.props.URLData.lng)
    ) {
      showError();
      self.props.URLData.lng = -78.549668;
    }
    if (
      self.props.URLData.lat < -90 ||
      self.props.URLData.lat > 90 ||
      isNaN(self.props.URLData.lat)
    ) {
      showError();
      self.props.URLData.lat = 39.014;
    }
    if (isNaN(self.props.URLData.zoomlevel)) {
      showError();
      self.props.URLData.zoomlevel = 7;
    }

    const map = new MapboxGl.Map({
      container: this.mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE.trim(),

      center: [self.props.URLData.lng, self.props.URLData.lat],
      zoom: self.props.URLData.zoomlevel,
    });

    map.on('load', (...args) => {
      this.setState({ map });
    });
    map.setMaxZoom(15);
    map.setMinZoom(6);

    const geocoder = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: MapboxGl.accessToken, // Set the access token
      mapboxgl: MapboxGl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      placeholder: 'Search for city', // Placeholder text for the search bar
      collapsed: true,
    });

    const geocoder2 = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: MapboxGl.accessToken, // Set the access token
      mapboxgl: MapboxGl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      placeholder: 'Search for an address', // Placeholder text for the search bar
    });

    document.getElementById('geocoder')?.appendChild(geocoder.onAdd(map));
    document.getElementById('geocoder2')?.appendChild(geocoder2.onAdd(map));

    map.addControl(new MapboxGl.NavigationControl());
    map.on('load', function () {
      map.resize();
      showProvidersOnMap();
    });

    const displayMarker = (lng, lat) => {
      const el = document.createElement('div');

      el.className = 'marker';
      // make a marker for each feature and add to the map
      new MapboxGl.Marker(el).setLngLat([lng, lat]).addTo(map);
    };

    let current_search = 0;

    geocoder2.on('result', async function (ev) {
      const coordinates = ev.result.center;

      displayMarker(coordinates[0], coordinates[1]);
      const locationSearch = document.getElementById('geocoder2');

      locationSearch.style.display = 'none';
      current_search = 1;
      sessionStorage.setItem('CURRENT_LOCATION', ev.result.place_name);
    });

    if (
      sessionStorage.getItem('CURRENT_LOCATION') &&
      sessionStorage.getItem('CURRENT_LOCATION') !== '' &&
      sessionStorage.getItem('CURRENT_LOC_COORDINATES')
    ) {
      const coordinates = sessionStorage.getItem('CURRENT_LOC_COORDINATES').split(',');

      displayMarker(coordinates[0], coordinates[1]);
    }

    geocoder.on('result', async function (ev) {
      document.querySelector('.geocoder .mapboxgl-ctrl-geocoder--input')?.blur();
      self.props.searchdata(ev);

      if (ev.result && ev.result.bbox) {
        zoomValue = 0;
      } else {
        self.setState({ providerData: [] });

        self.props.pData([]);
        self.props.isloaded();
      }
    });

    map.on('zoomend', async function (e) {
      if (zoomValue == 0) {
        zoomValue = zoomValue + 1;
        await showProvidersOnMap();
      } else {
        await updateProvidersOnMap();
      }
    });

    map.on('dragend', async function () {
      await updateProvidersOnMap();
    });

    const getAddress = async (lat, lng) => {
      const url =
        API_URLS.mapBoxURL +
        lng +
        ',' +
        lat +
        '.json?limit=1&access_token=' +
        APPLICATION_HEADERS.mapBoxAccessToken +
        '&language=en';
      const response = await axios.get(url);

      let place_name;
      const address = [];

      if (response && response.data.features && response.data.features.length > 0) {
        place_name = response.data.features[0].place_name;
        if (response.data.features[0].context) {
          let city, country, region, state;
          const addressData = {};

          _.filter(response.data.features[0].context, function (val) {
            if (_.includes(val['id'], 'place')) {
              city = val.text;

              region = val?.short_code?.toUpperCase();
              if (!val?.short_code) {
                region = val.text;
              }
            }
            if (_.includes(val['id'], 'region')) {
              state = val.text;
              region = val?.short_code?.toUpperCase();
              if (!val?.short_code) {
                region = val.text;
              }
            }

            if (_.includes(val['id'], 'country')) {
              country = val.text;

              if (!city) {
                city = val.text;
              }
              region = val.short_code.toUpperCase();
              if (!val.short_code) {
                region = val.text;
              }
            }

            addressData.city = city ? city : '';
            addressData.state = state ? state : '';
            addressData.country = country ? country : '';
            addressData.region = region ? region : state;
          });

          if (addressData && addressData.city && addressData.state) {
            address.cityState = addressData.city + ', ' + addressData.state;
          } else if (addressData && addressData.city) {
            address.cityState = addressData.city;
          } else if (addressData && addressData.state) {
            address.cityState = addressData.state;
          }

          address.place_name = place_name;
          address.country = country;
          address.region = addressData.region;
          address.city = addressData.city;
          if (!address.region) {
            address.region = addressData.city;
          }
          if (!address.country) {
            address.country = addressData.city;
          }
        } else if (response.data.features[0]) {
          let city, country, region, state;
          const addressData = {};
          const val = response.data.features[0];

          if (_.includes(val['id'], 'place')) {
            city = val.text;
          }
          if (_.includes(val['id'], 'region')) {
            state = val.text;
          }
          if (_.includes(val['id'], 'country')) {
            country = val.text;
            region = val.text;
          }

          addressData.city = city ? city : '';
          addressData.state = state ? state : '';
          addressData.country = country ? country : '';
          addressData.region = region ? region : '';

          address.place_name = country;
          address.country = country;
          address.region = addressData.region;
          address.city = addressData.city;
          if (!address.region) {
            address.region = addressData.city;
          }
          if (!address.country) {
            address.country = addressData.city;
          }
        }
      } else {
        address.cityState = '';
        address.place_name = 'Invalid center coordinates';
        address.region = '';
      }

      return address;
    };
    //to display mages for providers

    const providerIconsObj = [];

    providericons.map((providericon) => {
      providerIconsObj.push({
        url: `img-${providericon.name}`,
        name: providericon.name,
        imgName: providericon.img,
      });
    });

    const setPriceAndCenterData = async (isFirstRender = false) => {
      const bounds = map.getBounds();
      const sw = bounds.getSouthWest().wrap().toArray();
      const ne = bounds.getNorthEast().wrap().toArray();
      const bbox = sw.concat(ne);

      sessionStorage.setItem('BBOX', bbox);
      const markePoints = await getLocations(bbox);

      //get center place name when zoomin or dragging
      const place_name = await getAddress(map.getCenter().lat, map.getCenter().lng);

      const regionValue = getKeysByValue(place_name.region);

      const city = place_name['city'];

      setPriceNewData(city, regionValue, markePoints);

      //saveing center lat/lon when zoomin
      this.setState({
        centerData: [map.getCenter().lng, map.getCenter().lat, map.getZoom(), place_name],
      });
      //saving when current location add

      if (current_search === 1) {
        sessionStorage.setItem('CURRENT_LOC_COORDINATES', [
          map.getCenter().lng,
          map.getCenter().lat,
        ]);
        current_search = 0;
      }

      sessionStorage.setItem('ZoomLevel', map.getZoom());
      if (markePoints?.data?.length < 20 || map.getZoom() >= 10) {
        this.setState({ isRequestButtonEnabled: true });
      } else {
        this.setState({ isRequestButtonEnabled: false });
      }

      window.history.pushState(
        'page2',
        'Title',
        '/locations/place/' + map.getCenter().lng + '/' + map.getCenter().lat + '/' + map.getZoom(),
      );

      sessionStorage.setItem(
        'pathNeeded',
        '/locations/place/' + map.getCenter().lng + '/' + map.getCenter().lat + '/' + map.getZoom(),
      );

      // make locations coordinates create one obj
      const locationObj = _.map(markePoints.data, function (o) {
        const data = o;

        if (data && data.coordinates) {
          const coord = data.coordinates.split(',');
          const d = coord[1] + ',' + coord[0];
          const Obj = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: JSON.parse('[' + d + ']'),
            },
            providerName: data.provider_name,
          };

          return Obj;
        }
      });

      const providers = this.getFilteredData(markePoints);

      const topIcons = [];

      providerIconsObj.map((icon) => {
        //creating images for providers
        //condition for image icon display for providers
        if (isFirstRender && icon.imgName && !map.hasImage(icon.url)) {
          const img = new Image(20, 20);

          img.src = icon.imgName;
          img.onload = () => map.addImage(icon.url, img);
        }

        //mapping top5 providers with icons
        providers.map((p) => {
          if (p.toLowerCase().replace(/\s/g, '') === icon.name.toLowerCase().replace(/\s/g, '')) {
            topIcons.push({ url: icon.url, name: icon.name });
          }
        });
      });
      locationObj.map((location) => {
        let Obj;

        topIcons.map((n) => {
          if (n.name && location.providerName) {
            if (
              n.name.toLowerCase().replace(/\s/g, '') ===
              location.providerName.toLowerCase().replace(/\s/g, '')
            ) {
              Obj = {
                icon: n.url,
              };
              location.properties = Obj;
            }
          }
        });

        //providers which have images under other count
        providerIconsObj.map((n) => {
          if (n.name && location.providerName) {
            if (
              n.name.toLowerCase().replace(/\s/g, '') ===
              location.providerName.toLowerCase().replace(/\s/g, '')
            ) {
              Obj = {
                icon: n.url,
              };
              location.properties = Obj;
            }
          }
        });
        //Providers which has no image icon condition
        if (!location.properties && _.includes(providers, location.providerName)) {
          const Obj1 = {
            icon: 'img-other',
          };

          location.properties = Obj1;
        }

        //condition for Other prviders
        if (!location.properties) {
          Obj = {
            icon: 'img-other',
          };
          location.properties = Obj;
        }
      });

      return { markePoints, place_name, locationObj };
    };

    const showProvidersOnMap = async () => {
      if (map.getLayer('point')) {
        map.removeLayer('point');
      }
      if (map.getSource('single-point')) {
        map.removeSource('single-point');
      }

      const { locationObj } = await setPriceAndCenterData(true);

      const finalObject = {
        type: 'FeatureCollection',
        features: locationObj,
      };

      this.currentAllMarkers = locationObj;

      if (finalObject.features.length === 0) {
        self.props.isloaded();
      }
      try {
        map.addSource('single-point', {
          type: 'geojson',
          data: finalObject,
        });
        map.addLayer({
          id: 'point',
          source: 'single-point',

          type: 'symbol',
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': 1,
          },
        });
      } catch (error) {
        if (map.getLayer('point')) {
          map.removeLayer('point');
        }
        if (map.getSource('single-point')) {
          map.removeSource('single-point');
        }

        if (this.state.isMapPinsError) {
          toastify({
            title: 'Something went wrong',
            type: 'error',
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
        } else {
          showProvidersOnMap();
          this.setState({ isMapPinsError: true });
        }
      }
    };

    const setPriceData = async (markePoints, region) => {
      this.props.priceData([]);
      if (markePoints.data) {
        if (markePoints.data.length >= 5) {
          const priceDetailsRes = await getPriceDetails(markePoints);

          this.props.priceData(priceDetailsRes);
          this.props.region(region);
        } else {
          this.props.priceData([]);
          this.props.region(region);
        }
      }
    };

    const setPriceNewData = async (city, region, markePoints) => {
      this.props.priceData([]);

      if (city && typeof city !== 'object') {
        const priceDetailsRes = await getPriceDetails(markePoints);

        this.props.priceData(priceDetailsRes);
        this.props.region(region);
        // const priceDetailsRes = await getNewPriceDetails(city, markePoints);

        // if (priceDetailsRes && JSON.parse(priceDetailsRes).data.priceList.length > 0) {
        //   this.props.priceData(priceDetailsRes);
        //   this.props.region(region);
        // } else {
        //   //call old API
        //   const priceDetailsRes = await getPriceDetails(markePoints);

        //   this.props.priceData(priceDetailsRes);
        //   this.props.region(region);
        // }
      } else {
        this.props.priceData([]);
        this.props.region(region);
      }
    };

    const updateProvidersOnMap = async () => {
      const { locationObj } = await setPriceAndCenterData();

      const finalObject = {
        type: 'FeatureCollection',
        features: locationObj,
      };

      this.currentAllMarkers = locationObj;

      if (finalObject.features.length === 0) {
        self.props.isloaded();
      }
      try {
        map.getSource('single-point').setData(finalObject);
      } catch (error) {
        if (map.getLayer('point')) {
          map.removeLayer('point');
        }
        if (map.getSource('single-point')) {
          map.removeSource('single-point');
        }
      }
    };

    const getLocations = async (geoData) => {
      const headers = APPLICATION_HEADERS.agilePythonAPIKey;
      const locationObj = {
        method: 'box',
        lower_left: {
          Longitude: geoData[0],
          Latitude: geoData[1],
        },
        upper_right: {
          Longitude: geoData[2],
          Latitude: geoData[3],
        },
        mode: 'noslash',
      };

      this.props.priceData([]);
      this.props.pData([]);
      const url = API_URLS.providerLocationsURL;

      try {
        const response = await axios.post(url, locationObj, { headers });

        this.setState({ providerData: response });
        this.props.pData(response);

        return response;
      } catch (error) {
        return error;
      }
    };

    async function getNewPriceDetails(city, markePoints) {
      const url = `${API_URLS.actualPricingURL}?city=` + city;

      const headers = APPLICATION_HEADERS.mkiiAPIKey;

      try {
        const responsePrice = await axios.get(url, { headers });

        return JSON.stringify(responsePrice);
      } catch (error) {
        return error;
      }
    }

    async function getPriceDetails(response) {
      const url = API_URLS.pricingURL;

      const headers = APPLICATION_HEADERS.agilePythonAPIKey;

      try {
        const data = [];

        for (let index = 0; index < response.data.length; index++) {
          const row = response.data[index];
          const geoCord = row.coordinates.split(',');

          data.push({
            Latitude: geoCord[0],
            Longitude: geoCord[1],
            Unit: 25,
            Term: 6,
            Provider: row.provider_name,
          });
        }
        const priceObj = {
          method: 'full',
          records: data,
        };

        const responsePrice = await axios.post(url, JSON.stringify(priceObj), {
          headers,
        });

        return JSON.stringify(responsePrice);
      } catch (error) {
        return error;
      }
    }
  }

  gotoCurrentLocation = async () => {
    await this.updateProvidersOnMap();
  };
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState !== this.state;
  }
  handleAddClient = (event) => {
    event.preventDefault();
    const listmenu = document.getElementById('addClientBtn');

    listmenu.style.display = 'none';
    document.querySelector('.geocoder2 .mapboxgl-ctrl-geocoder--input').value = '';
    const locationSearch = document.getElementById('geocoder2');

    locationSearch.style.display = 'flex';
    locationSearch.value = '';
  };

  render() {
    const { children } = this.props;
    const { map, isRequestButtonEnabled, showingBtn } = this.state;

    if (sessionStorage.getItem('CURRENT_LOCATION') && document.getElementById('addClientBtn')) {
      document.getElementById('addClientBtn').style.display = 'none';
    }

    if (!sessionStorage.getItem('CURRENT_LOCATION')) {
      !this.state.showingBtn && this.setState({ showingBtn: true });
    }

    if (this.currentLocationInput.current !== null) {
      this.currentLocationInput.current.style.display = !showingBtn
        ? 'none !important'
        : 'flex !important';
    }

    return (
      <div className="mapDisplay">
        <div className="mapdisplaybuttons">
          <div className="addClient">
            <div className="tooltip1" id="addClientBtn" ref={this.currentLocationInput}>
              <img src={add_client} onClick={this.handleAddClient} />
              <span className="tooltiptext1">Add a client location pin to map</span>
            </div>
            <CurrentLocation locData={this.state.locData} />
            <div id="geocoder2" className="geocoder2"></div>
          </div>

          <RequestSurveyBtn
            centerData={this.state.centerData}
            providerdata={this.state.providerData}
            IsRequestButtonEnabled={isRequestButtonEnabled}
          />
        </div>

        <div className="map-container-box" ref={this.mapContainer}>
          {map && children}
        </div>
      </div>
    );
  }
}

export default MapLocations;
